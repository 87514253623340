import clsx from "clsx";
// import { useRiskItemQuery } from "../../features";
import { ApiInfo, InfoDescription, InfoPanel, Panel } from "../../ui";
import { DynamicsBarChart } from "../../ui/bar-chart/dynamics-bar-chart";
import s from "./press-secretary.module.css";

type Props = {
  title: string;
  id: number;
  number: number;
};

const charData = [
  { name: "1", value: 2000 },
  { name: "2", value: 1000 },
  { name: "3", value: 1500 },
  { name: "4", value: 1700 },
  { name: "5", value: 1200 },
  { name: "5", value: 1320 },
  { name: "5", value: 800 },
];

const data = {
  people: undefined,
  views: undefined,
  likes: undefined,
  comments: undefined,
  reposts: undefined,
};

export function PressSecretary({ title, id, number }: Props) {
  // const queryData = useRiskItemQuery(id);
  // const { data, isSuccess, isLoading, isError, error } = queryData;

  // if (!isSuccess) {
  //   return (
  //     <InfoPanel title="" number={number}>
  //       <ApiInfo isLoading={isLoading} isError={isError} error={error} />
  //     </InfoPanel>
  //   );
  // }

  return (
    <InfoPanel
      contentClassName={s.columns}
      title={title}
      number={number}
      statistics={data}
    >
      <InfoDescription title="Количество размещенных готовых материалов:" />
      <InfoDescription title="Количество активных рисков:" />
      <InfoDescription title="Количество источников информации:" />
      <InfoDescription title="Количество отработанных рисков:" />
      <InfoDescription title="Количество публикаций:" />
      <div className={s.diagram}>
        <p>Динамика размещения ГМ</p>
        <Panel padding className={clsx(s.panel, "not-ready")}>
          <DynamicsBarChart
            className={s.barChart}
            dataKey="value"
            color="#4EC0E4"
            data={charData}
            height={68}
          />
        </Panel>
      </div>
    </InfoPanel>
  );
}
