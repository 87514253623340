import clsx from "clsx";
import {ChangeEvent, useEffect, useState} from "react";
import { useRiskListQuery, useScrollPercent } from "../../features";
import { ApiInfo, Field, PageTitle, Panel, TextLoader } from "../../ui";
import { Risk } from "./risk";
import s from "./risks.module.css";

export function Risks() {
  const [searchString, setSearchString] = useState('');
  const [searchAuthor, setSearchAuthor] = useState('');
  const [searchIOGV, setSearchIOGV] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const queryData = useRiskListQuery({ start: 0, limit: 1000, filter:{author:searchAuthor?searchAuthor:'', iogv_id:+searchIOGV, status:searchStatus} });
  const { data, isSuccess, isLoading, isError, error } = queryData;
  const [filteredData, setFilteredData] = useState(data?.items || []);
  useEffect(() => {
    if (data?.items) {
      if (searchString === '') {
        setFilteredData(data.items)
      } else {
        const filteredItems = data.items.filter((it: { title: string }) => it.title.toLowerCase().includes(searchString.toLowerCase()))
        setFilteredData(filteredItems)
      }
    }
  }, [searchString, data])

  const searchHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchString(evt.target.value)
  }

  const searchHandleAuthor = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchAuthor(evt.target.value)
  }

  const searchHandleIOGV = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchIOGV(evt.target.value)
  }

  const searchHandleStatus = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchStatus(evt.target.value)
  }


  return (
    <>
      <PageTitle>Риски</PageTitle>
      <Panel className={s.search} padding>
        <Field className={s.field} icon="search" onChange={searchHandle} value={searchString} placeholder="Введите название" />
        <Field className={s.field} icon="search" onChange={searchHandleAuthor} value={searchAuthor} placeholder="Введите ответственного" />
        <Field className={s.field} icon="search" onChange={searchHandleStatus} value={searchStatus} placeholder="Введите статус" />
        <span className={s.count}>
          Всего рисков:{" "}
          {isLoading ? <TextLoader /> : filteredData.length ?? "–"}
        </span>
      </Panel>

      <ApiInfo isLoading={isLoading} isError={isError} error={error} />

      {isSuccess && (
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <Risk key={item.id} number={index + 1} {...item} />
          ))}
        </div>
      )}
    </>
  );
}
