import clsx from "clsx";
import { useEffect, useState } from "react";
import { useRiskListQuery } from "../../features";
import { ApiInfo, PageTitle, Panel, Field } from "../../ui";
import { PressSecretary } from "./press-secretary";
import s from "./press-secretaries.module.css";

export function PressSecretaries() {
  const queryData = useRiskListQuery({ start: 0, limit: 5 });
  const { data, isSuccess, isLoading, isError, error } = queryData;
  return (
    <>
      <PageTitle>Работа Пресс-секретарей</PageTitle>
      <Panel className={s.search} padding>
        <Field className={clsx(s.field, "not-ready")} />
      </Panel>

      <ApiInfo isLoading={isLoading} isError={isError} error={error} />

      {isSuccess && (
        <div className={clsx(s.list, "not-ready")}>
          {data?.items.map((item: any, index: number) => (
            <PressSecretary key={item.id} number={index + 1} {...item} />
          ))}
        </div>
      )}
    </>
  );
}
