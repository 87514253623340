// import clsx from "clsx";
import clsx from "clsx";
import {ChangeEvent, useEffect, useState} from "react";
import {
  useThreadsQuery,
  useTopicsQuery,
  useSmiQuery,
  mergeQueries,
  useTopicRatingQuery
} from "../../features";
import { useAppSelector } from "../../redux";
import {
  Button,
  PageTitle,
  Panel,
  ApiInfo,
  NoData,
  Topic,
  Trend,
  Select,
} from "../../ui";
import s from "./trending.module.css";

const TopicList = ({ items }: any) => {
  return (
    <div className={s.list}>
      {items.map((item: any, index: number) => (
        <Topic key={item.id} number={index + 1} data={item} />
      ))}
    </div>
  )
}

export const Trending = () => {
  type SateType = "subject" | "event" | "smi";
  type PeriodType = "day" | "week" | "month";
  const [type, setType] = useState<SateType>("subject");
  const [period, setPeriod] = useState<PeriodType>("day");

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID, REACT_APP_THREAD_ID } = process.env;

  const thread_id = REACT_APP_THREAD_ID;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;

  const user_id = useAppSelector((state) => state.auth.userId);

  // I have no idea why, but we have to pass all of this
  // to the api (even if it works without)
  const wtfHardCodedParams = {
    reference_item_id: 0,
    // type: "social",
    type: "smi",
    user_id,
    from: "",
    to: "",
    // sort: {
    //   type: "rating",
    //   order: "desc"
    // },
    user_filter: null
  };

  // ! ADD INFINITE SCROLLING

  const skipParams = { skip: !thread_id || type === "smi" };
  const topicsRatingQuery = useTopicRatingQuery(skipParams);

  const smiQuery = useSmiQuery("", { skip: type !== "smi" });
  const allQueries = mergeQueries(topicsRatingQuery, smiQuery);

  console.info(`🔥 allQueries`, allQueries);
  const { isSuccess, isFetching, isError, error } = allQueries;
  const items = topicsRatingQuery.data;
  const [filteredData, setFilteredData] = useState([] as any[]);
  const [searchString, setSearchString] = useState('');
  const [onlyFederal, setOnlyFederal] = useState(false);
  const hasItems = filteredData ? filteredData.length > 0 : false;

  useEffect(() => {
    setFilteredData([])
    if (items) {
      let currentItems = []
      if (searchString === '') {
        currentItems = items
      } else {
        console.log(items)
        currentItems = [...items].filter((it: { reference_item: string, title: string }) => it.reference_item.toLowerCase().includes(searchString.toLowerCase()) || it.title.toLowerCase().includes(searchString.toLowerCase()))
      }
      setFilteredData(onlyFederal ? [...currentItems].filter((it: any) => it.smi_type === "federal") : currentItems)
    }
  }, [searchString, onlyFederal, items])

  console.log('filteredData', filteredData)

  const searchHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchString(evt.target.value)
  }

  return (
    <>
      <PageTitle>Быстрорастущие темы за день</PageTitle>

      <ApiInfo isLoading={isFetching} isError={isError} error={error} />
      {isSuccess && filteredData.length < 1 && <NoData />}
      {isSuccess && filteredData.length > 0 && (
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <Trend key={item.id} number={index + 1} data={item} referenceId={reference_id} />
          ))}
        </div>
      )}
    </>
  );
};
