import clsx from "clsx";
import { useParams } from "react-router-dom";
import {
  FilterData,
  mergeQueries,
  Publication, PublicationsFilters,
  toLocaleDateTime, useIndicatorsTopQuery,
  useInfiniteQuery, usePostCountQuery,
  usePublicationsQuery,
  useRiskItemQuery, useSmiGraphQuery, useSmiTopQuery, useSocGraphQuery, useSocTopQuery,
  useThreadItemQuery, useTopStatsQuery, useTrustHourlyQuery, useUnatedGraphQuery
} from "../../../features";

import {
  Panel,
  InfoPanel,
  PageTitle,
  InfoDescription,
  Table, ApiInfo, NoData, BarChart,
} from "../../../ui";
import { Title } from "../../../ui/panel/title";
import s from "./material-info.module.css";
import {trustMap, TrustValue} from "../../../data";
import {useEffect, useState} from "react";
import {Bar, Legend, XAxis, YAxis, Tooltip} from "recharts";

type ApiParams = {
  thread_id?: string;
  from?: string;
  to?: string;
  start?: number;
  limit?: number;
  filter?: {
    network_id?: number | number[];
    trustoption?: TrustValue;
    referenceFilter?: number[];
  };
};

export function MaterialInfo() {
  let params = useParams();
  const [apiParams, setApiParams] = useState<ApiParams>({ thread_id: params.id });
  const [expanded, expandFilters] = useState(false);
  const [postCount, setPostCount] = useState({
    total: {
      posts: 0,
      likes: 0,
      reposts: 0,
      comments: 0
    },
    positive: {
      posts: 0,
      likes: 0,
      reposts: 0,
      comments: 0
    },
    negative: {
      posts: 0,
      likes: 0,
      reposts: 0,
      comments: 0
    },
    netural: {
      posts: 0,
      likes: 0,
      reposts: 0,
      comments: 0
    },
  })
  //let url=decodeURI(window.location.search).replace("?it_filter=", '')

  // const queryData = useRiskItemQuery(params.id as any as number);
  const queryData = useThreadItemQuery({ thread_id: +params.id! });
  const { data, isSuccess, isLoading } = queryData;

  const { items: posts, query } = useInfiniteQuery(
    usePublicationsQuery,
    apiParams,
    (res: any) => res.data?.posts,
    !apiParams.thread_id
  );
  const allQueries = mergeQueries(query);
  const { isFetching, isError, error } = allQueries;
  // console.log("query", queryData);
  const trustHourlyQueryData = useTrustHourlyQuery({ thread_id: +params.id! });
  const topStatsQueryData = useTopStatsQuery({ thread_id: +params.id! });
  const postCountQueryData = usePostCountQuery({ thread_id: +params.id! });

  useEffect(() => {
    if (postCountQueryData?.data) {
      const sourcesNames = Object.keys(postCountQueryData.data)
      const counts = {
        total: {
          posts: 0,
          likes: 0,
          reposts: 0,
          comments: 0
        },
        positive: {
          posts: 0,
          likes: 0,
          reposts: 0,
          comments: 0
        },
        negative: {
          posts: 0,
          likes: 0,
          reposts: 0,
          comments: 0
        },
        netural: {
          posts: 0,
          likes: 0,
          reposts: 0,
          comments: 0
        },
      }
      sourcesNames.forEach((it: string) => {
        const typeNames = Object.keys(postCountQueryData.data[it])
        typeNames.forEach((typeItem: string) => {
          // @ts-ignore
          counts[typeItem].posts += postCountQueryData.data[it][typeItem].posts
          // @ts-ignore
          counts[typeItem].likes += postCountQueryData.data[it][typeItem].likes
          // @ts-ignore
          counts[typeItem].reposts += postCountQueryData.data[it][typeItem].reposts
        })
      })
      setPostCount(counts)
    }
  }, [postCountQueryData])

  // const tableData = []
  const tableData = [
    {
      title: "Название",
      subtitle: "Количество публикаций",
      figures: ['', postCount.total.posts, postCount.positive.posts, postCount.netural.posts, postCount.negative.posts],
    },
    {
      title: "Всего",
      subtitle: "Количество комментариев",
      // figures: ['', postCount.total.comments, postCount.positive.comments, postCount.netural.comments, postCount.negative.comments],
      figures: ['', '-', '-', '-', '-'],
    },
    {
      title: "Поз.",
      subtitle: "Количество репостов",
      figures: ['', postCount.total.reposts, postCount.positive.reposts, postCount.netural.reposts, postCount.negative.reposts],
    },
    {
      title: "Нейт.",
      subtitle: "Количество лайков",
      figures: ['', postCount.total.likes, postCount.positive.likes, postCount.netural.likes, postCount.negative.likes],
    },
    {
      title: "Негат.",
      subtitle: "Количество просмотров",
      figures: ['', topStatsQueryData.data?.graph_data[topStatsQueryData.data?.graph_data.length - 1][1] || 0, 0, topStatsQueryData.data?.graph_data[topStatsQueryData.data?.graph_data.length - 1][1] || 0, 0],
    },
  ];

  let unatedGraphData: any[] = [];
  if (trustHourlyQueryData.data) {
    const graphData: {social: any[], smi: any[]} = {
      social: [],
      smi: []
    }
    const sourceSelector = Object.keys(trustHourlyQueryData.data)
    sourceSelector.forEach((selector: string) => {
      if (selector !== 'gs' && selector !== 'total') {
        // @ts-ignore
        if (graphData.social.length === 0) {
          // @ts-ignore
          graphData.social = trustHourlyQueryData.data[selector].total
        } else {
          const tempSocial: any[] = []
          trustHourlyQueryData.data[selector].total.forEach((it: any, idx: number) => {
            tempSocial.push([graphData.social[idx][0], graphData.social[idx][1] + it[1]])
          })
          graphData.social = tempSocial
        }
      }
      if (selector === 'gs') {
        // @ts-ignore
        graphData.smi = trustHourlyQueryData.data[selector].total
      }

    })
    unatedGraphData = graphData.social.map((it: any, idx: number) => {
      const smiValue = graphData.smi[idx][1] || 0;
      return {
        name: it[0],
        СМИ: smiValue,
        Соцсети: it[1] || 0,
      }
    }) || [];
  }

  function onFiltersUpdate(data: FilterData) {
    setApiParams((params) => ({
      ...params,
      to: data.to,
      from: data.from,
      filter: {
        ...params.filter,
        network_id: data.network_id,
        trustoption: data.trustoption,
      },
    }));
  }

  if (!isSuccess) {
    return (
      <InfoPanel title="">
        <ApiInfo isLoading={isLoading} isError={isError} error={error} />
      </InfoPanel>
    );
  }

  return (
    <>
      <PageTitle>Готовые материалы</PageTitle>

      <InfoPanel
        className={s.infopanel}
        title={queryData.data?.title}
        //number={1}
        // statistics={{ publications: 0, status: "-" }}
      >
        <InfoDescription
          className={s.description}
          title="Описание:"
          description={data.description || "-"}
        />
        <InfoDescription
          className={s.description}
          title="Подпрограмма:"
          description={data.subroutine?.map((it: string) => {
            return <span>{it}</span>
          })}
        />
        <InfoDescription
          className={s.description}
          title="Ссылка:"
          description={data.url || "-"}
        />
        <div className={s.columns}>
          <InfoDescription
            className={s.description}
            title="Региональный проект:"
            description={data.regin_project?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            className={s.description}
            title="Направления стратегии:"
            description={data.stategy?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            className={s.description}
            title="Сфера:"
            description={data.sphere?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            className={s.description}
            title="Каналы:"
            description={data.channels?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            className={s.description}
            title="Локация:"
            description={data.location?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            title="Целевая аудитория:"
            description={data.ca?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            title="Дата/время начала:"
            description={data.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(data.date_start)}
          />
          <InfoDescription
            title="Дата/время окончания:"
            description={data.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(data.date_finish)}
          />
          <InfoDescription title="Ответственный:" description={data.responsible} />
          <InfoDescription title="Соисполнители:" description={data.sub_responsible?.length > 0 ? data.sub_responsible?.map((it: string, idx: number) => {
            if (idx < data.sub_responsible.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Участники:" description={data.members?.length > 0 ? data.members?.map((it: string, idx: number) => {
            if (idx < data.members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Дополнительные участники:" description={data.ext_members?.length > 0 ? data.ext_members?.map((it: string, idx: number) => {
            if (idx < data.ext_members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Автор:" description={data.author} />
          <InfoDescription title="Хэштеги:" description={data.hashtag?.length > 0 ? data.hashtag?.map((it: string, idx: number) => {
            if (idx < data.hashtag.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Риски:" description={data.risks?.length > 0 ? data.risks?.map((it: string, idx: number) => {
            if (idx < data.risks.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
        </div>
      </InfoPanel>

      <div className={s.tables}>
        <Panel className={s.table}>
          <div className={s.header}>
            <Title className={s.title} caption="Показатели" />
            <span className={s.subtitle}>Данные за текущий день</span>
          </div>
          <Table data={tableData} />
        </Panel>
        <Panel className={s.panel} padding>
          <BarChart data={unatedGraphData} height={299}>
            <Bar dataKey="СМИ" fill="#8ACE21" />
            <Bar
              dataKey="Соцсети"
              fill="#4EC0E4"
            />
            <XAxis dataKey="name" />
            <Tooltip/>
            <YAxis />
            <Legend verticalAlign="top" align="right" />
          </BarChart>
        </Panel>
      </div>

      <div className={s.publicationsWrap}>
        <div className={s.filterArea}>
          <PublicationsFilters
            expanded={expanded}
            onExpandToggle={expandFilters}
            onFilterChange={onFiltersUpdate}
          />
        </div>

        <div className={s.publications}>
          {!isFetching && !isError && posts.length === 0 && <NoData />}
          {posts.map((post: any) => (
            <Publication
              key={post.id}
              post={{
                text: post.text,
                title: post.title,
                coat: post.smi_type === "federal",
                date: new Date(post.created_date),
                // @ts-ignore
                type: trustMap[post.trust.trust],
                author: {
                  name: post.author,
                  avatar: post.author_logo,
                  url: post.author_url,
                },
                statistics: {
                  people: post.attendance,
                  views: post.viewed,
                  likes: post.likes,
                  comments: post.comments,
                  reposts: post.reposts,
                },
                networkName: post.network_name
              }}
              actions={{
                onCreateClick: () => console.log("onCreateClick"),
                onExcludeClick: () => console.log("onExcludeClick"),
                onReadClick: () => console.log("onReadClick"),
                onToneClick: () => console.log("onToneClick"),
              }}
            />
          ))}
          <ApiInfo isLoading={isFetching} isError={isError} error={error} />
        </div>
      </div>
    </>
  );
}
