import { useNewsbreakListQuery } from "../../features";
import { Field, PageTitle, Panel, ApiInfo, TextLoader } from "../../ui";
import { NewsBreakItem } from "./newsbreakItem";
import s from "./newsbreak.module.css";
import clsx from "clsx";
import {useState, ChangeEvent, useEffect} from "react";

export function Newsbreak() {
  const [searchString, setSearchString] = useState('');
  const [searchAuthor, setSearchAuthor] = useState('');
  const [searchIOGV, setSearchIOGV] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const queryData = useNewsbreakListQuery({ start: 0, limit: 1000, filter:{author:searchAuthor?searchAuthor:'', iogv_id:+searchIOGV, status:searchStatus} });
  const [filteredData, setFilteredData] = useState([]);
  const { data, isSuccess, isLoading, isError, error } = queryData;

  useEffect(() => {
    if (data?.items) {
      if (searchString === '') {
        setFilteredData(data.items)
      } else {
        const filteredItems = data.items.filter((it: { title: string }) => it.title.toLowerCase().includes(searchString.toLowerCase()))
        setFilteredData(filteredItems)
      }
    }
  }, [searchString, data])

  const searchHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchString(evt.target.value)
  }

  const searchHandleAuthor = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchAuthor(evt.target.value)
  }

  const searchHandleIOGV = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchIOGV(evt.target.value)
  }

  const searchHandleStatus = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchStatus(evt.target.value)
  }

  const infoCount = useNewsbreakListQuery({ start: 0, limit: 0 }).data?.items.length

  return (
    <>
      <PageTitle>Инфоповоды</PageTitle>
      <Panel className={s.search} padding>
        <Field className={s.field} icon="search" onChange={searchHandle} value={searchString} placeholder="Введите название" />
        <Field className={s.field} icon="search" onChange={searchHandleAuthor} value={searchAuthor} placeholder="Введите автора" />
        <Field className={s.field} icon="search" onChange={searchHandleStatus} value={searchStatus} placeholder="Введите статус" />
        <span className={s.count}>
          Всего инфоповодов:{" "}
          {isLoading ? <TextLoader /> : filteredData.length ?? "–"}
        </span>
      </Panel>

      <ApiInfo isLoading={isLoading} isError={isError} error={error} />

      {isSuccess && (
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <NewsBreakItem key={item.id} number={index + 1} {...item} />
          ))}
        </div>
      )}
    </>
  );
}
