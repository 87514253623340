import type { Data } from "../../features";
import clsx from "clsx";
import { Panel } from "../panel";
import { Man } from "../man";
import s from "./post-media.module.css";
import { PublicationsChart } from "../../features";
import { TopicDescription } from "../topic-description";

type Props = {
  number: number;
  data: PostMediaData;
  className?: string;
  coat?: boolean;
};

export type PostMediaData = {
  id: string;
  group_id: string;
  subject: string;
  level: number;
  topic: string;
  subjectPublications: number;
  totalPublications: number;
  subjectCoverage: number;
  totalCoverage: number;
  dynamicData: Data[];
  mediaPublications?: number;
  socialMediaPublications?: number;
  storyDocs?: number;
  lastHourDocs?: number;
};

export function PostMedia({ className, number, coat = false, data }: Props) {
  const {
    group_id,
    subject,
    level,
    topic,
    subjectPublications,
    subjectCoverage,
    mediaPublications,
    totalPublications,
    totalCoverage,
    socialMediaPublications,
    dynamicData,
    storyDocs,
    lastHourDocs
  } = data;
  return (
    <Panel padding coat={coat} number={number} className={className}>
      <div className={s.media}>
        <div className={s.theme}>
        <p className={s.theme__caption}>Тема:</p>
          <h2 className={s.theme__title}>{topic}</h2>
          {subject &&
          <>
          <p className={s.person__caption}>Cубъект:</p>
          <p className={s.person__name}>{subject ?? "–"}</p>
          </>
          }

          <div className={s.data}>
            <div className={s.dataCol}>
              {subjectPublications && subjectPublications!=0 &&
              <TopicDescription
                title="Публикаций с упоминанием субъекта"
                description={subjectPublications}
                coverage={subjectCoverage}
              />
              }
              {totalPublications && totalPublications!=0 &&
              <TopicDescription
                title="Всего публикаций в теме"
                description={totalPublications}
                coverage={totalCoverage}
              />
              }
              {storyDocs && storyDocs!=0 &&
              <TopicDescription
                title="Количество источников"
                description={storyDocs}
                plus={lastHourDocs}
              />
            }
            </div>
            <div className={s.dataCol}>
            {mediaPublications && mediaPublications!=0 &&
              <TopicDescription
                title="Количество публикаций в СМИ"
                description={mediaPublications}
              />
            }
            {socialMediaPublications != null &&
              <TopicDescription
                title="Количество публикаций в cоц.сетях"
                description={socialMediaPublications}
              />
            }
            </div>
          </div>
        </div>
        {dynamicData &&
        <div className={s.diagrams}>
          {/*<div className={clsx(s.diagram, { "not-ready": !dynamicData })}>*/}
          <div className={s.diagram}>
            <p>Динамика публикаций:</p>
            <Panel className={clsx(s.diagramPanel)}>
              {dynamicData && <PublicationsChart data={dynamicData} />}
            </Panel>
          </div>

          {/*<div className={clsx(s.diagram, "not-ready")}>*/}
          {/*  <p>Тональность публикаций:</p>*/}
          {/*  <Panel className={s.diagramPanel} padding>*/}
          {/*    GRAPHIC*/}
          {/*  </Panel>*/}
          {/*</div>*/}
        </div>
        }
      </div>
    </Panel>
  );
}
