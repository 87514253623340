import clsx from "clsx";
import { PostMedia } from "..";
import { TopicPopup } from "../../features";
import s from "./topic.module.css";

type Props = {
  data: any;
  number: number;
  className?: string;
  referenceId?: string;
};

export const Topic = ({ number, className, data, referenceId }: Props) => {
  console.log('data', data)
  const postMediaData = {
    id: data.id,
    group_id: data.group_id,
    topic: data.title,
    subject: data.reference_item,
    level: 0,
    subjectPublications: data.postcount,
    subjectCoverage: data.attendance,
    totalPublications: data.total_posts,
    totalCoverage: data.total_attendance,
    dynamicData: data.total_graph,
    mediaPublications: data.smi_total_posts,
    socialMediaPublications: data.social_total_posts,
    storyDocs: data.storyDocs,
    lastHourDocs: data.lastHourDocs
  };

  const content = (
    <div className={className}>
      <PostMedia
        number={number}
        coat={data.smi_type === "federal"}
        className={s.topic}
        data={postMediaData}
      />
    </div>
  );

  return <TopicPopup content={content} data={postMediaData} referenceId={referenceId} />;
};
