import {toLocaleDateTime, useRiskItemQuery, useThreadItemQuery} from "../../features";
import { ApiInfo, InfoDescription, InfoPanel, Panel } from "../../ui";
import s from "./material.module.css";
import {Link} from "react-router-dom";

type Props = {
  title: string;
  id: string;
  number: number;
};

export function Material({ title, id, number }: Props) {
  const queryData = useThreadItemQuery({ thread_id: +id });
  const { data, isSuccess, isLoading, isError, error } = queryData;

  if (!isSuccess) {
    return (
      <InfoPanel title="" number={number}>
        <ApiInfo isLoading={isLoading} isError={isError} error={error} />
      </InfoPanel>
    );
  }

  if (data?.thread_id === 0) {
    return null
  }

  return (
    <Link to={`${id}`}>
      <InfoPanel
        title={title}
        number={number}
        statistics={{ publications: 0, status: "-" }}
      >
        <InfoDescription
          className={s.description}
          title="Название:"
          description={data?.title || "-"}
        />
        <InfoDescription
          className={s.description}
          title="Описание:"
          description={data.description || "-"}
        />
        <InfoDescription
          className={s.description}
          title="Подпрограмма:"
          description={data.subroutine?.map((it: string) => {
            return <span>{it}</span>
          })}
        />
        <InfoDescription
          className={s.description}
          title="Ссылка:"
          description={data.url.length>0 ? data.url : "-"}
        />
        <div className={s.columns}>
          <InfoDescription
            //className={s.description}
            title="Региональный проект:"
            description={data.regin_project?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Направления стратегии:"
            description={data.stategy?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Сфера:"
            description={data.sphere?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Каналы:"
            description={data.channels?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Локация:"
            description={data.location?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            title="Целевая аудитория:"
            description={data.ca?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            title="Дата/время начала:"
            description={data.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(data.date_start)}
          />
          <InfoDescription
            title="Дата/время окончания:"
            description={data.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(data.date_finish)}
          />
          <InfoDescription title="Ответственный:" description={data.responsible} />
          <InfoDescription title="Соисполнители:" description={data.sub_responsible?.length > 0 ? data.sub_responsible?.map((it: string, idx: number) => {
            if (idx < data.sub_responsible.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Участники:" description={data.members?.length > 0 ? data.members?.map((it: string, idx: number) => {
            if (idx < data.members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Дополнительные участники:" description={data.ext_members?.length > 0 ? data.ext_members?.map((it: string, idx: number) => {
            if (idx < data.ext_members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Автор:" description={data.author} />
          <InfoDescription title="Хэштеги:" description={data.hashtag?.length > 0 ? data.hashtag?.map((it: string, idx: number) => {
            if (idx < data.hashtag.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Риски:" description={data.risks?.length > 0 ? data.risks?.map((it: string, idx: number) => {
            if (idx < data.risks.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
        </div>
      </InfoPanel>
    </Link>
  );
}
