import clsx from "clsx";
import {
  Man,
  Panel,
  PopupContent, Post,
  PostMediaData,
  TopicDescription,
} from "../../../ui";
import { Popup } from "../../../ui";
import { Publication } from "../../publications";
import s from "./topic-popup.module.css";
import {useThreadsQuery} from "../../threads";
import {useTopicsModalQuery} from "../topics-api";

type Props = {
  content: PopupContent;
  data: PostMediaData;
  className?: string;
  referenceId?: string;
};

const statistic = {
  people: 3327,
  views: 1823,
  likes: 64,
  comments: 6,
  reposts: 27,
};

const title = "nw24.ru";
const text =
  "У исторического здания бывшего старейшего в России завода по производству резиновой обуви нет нормального хозяина, не считая государство, которое не способно сделать ничего хорошего. Более того, у Петербурга сейчас толком нет хозяина.";

const author = {
  name: "qwe",
  avatar: "",
  url: "https://yandex.ru",
};

export const TopicPopup = ({ content, className, data, referenceId }: Props) => {
  const publicationsQuery = useTopicsModalQuery({
    id: data.id.length > 10 ? data.id : data.group_id,
    type: 'all',
    referenceFilter: [parseInt(referenceId!)]
  });

  if (publicationsQuery.data) {
    console.log('publicationsQuery.data', publicationsQuery.data)
  }

  return (
    <Popup className={clsx(className, s.root)} content={content}>
      <div className={s.header}>
        <div className={s.descriptions}>
        <h4>Тема: {publicationsQuery.data?.title}</h4>
        {data.subjectPublications &&
          <TopicDescription
            title="Публикаций с упоминанием субъекта"
            description={data.subjectPublications}
            coverage={data.subjectCoverage}
          />
        }
        {data.totalPublications &&
          <TopicDescription
            title="Всего публикаций в теме"
            description={data.totalPublications}
            coverage={data.totalCoverage}
          />
        }
        {publicationsQuery.data?.items.filter((it: any) => it.network_id === '4').length !=0 &&
          <TopicDescription
            title="Количество публикаций в СМИ"
            description={publicationsQuery.data?.items.filter((it: any) => it.network_id === '4').length || 0}
          />
        }
          {publicationsQuery.data?.items.filter((it: any) => it.network_id !== '4').length != 0 &&
          <TopicDescription
            title="Количество публикаций в cоц.сетях"
            description={publicationsQuery.data?.items.filter((it: any) => it.network_id !== '4').length || 0}
          />
          }
        </div>
      </div>
      <div className={s.publications}>
        {publicationsQuery.data && publicationsQuery.data.items
          ? publicationsQuery.data.items.map((it: any) => (
            // <Post
            //   title={it.title}
            //   author={it.author}
            //   text={it.text}
            //   coat={false}
            //   date={new Date(it.created_date)}
            //   statistics={{
            //     people: 0,
            //     views: 0,
            //     likes: 0,
            //     comments: 0,
            //     reposts: 0,
            //   }}
            // />
            <Publication
              // post={it}
              post={{
                date: new Date(it.created_date),
                coat: it.smi_type !== 'local',
                title: it.title,
                author: {
                  name: it.author,
                  avatar: it.author_logo,
                  url: it.author_url
                },
                text: it.text,
                statistics: {
                  people: it.attendance,
                  views: it.viewed,
                  likes: it.likes,
                  comments: it.comments,
                  reposts: it.reposts,
                },
                type: "default",
                networkName: it.network_name
              }}
              actions={{
                onCreateClick: () => console.log("onCreateClick"),
                onExcludeClick: () => console.log("onExcludeClick"),
                onReadClick: () => console.log("onReadClick"),
                onToneClick: () => console.log("onToneClick"),
              }}
            />
          ))
          : null
        }
      </div>
    </Popup>
  );
};
