import type { TrustValue } from "../../data";
import type { FilterData } from "../../features/publications";
import clsx from "clsx";
import { useState, useEffect } from "react";
import {
  mergeQueries,
  Publication,
  PublicationsFilters,
  useInfiniteQuery,
  usePublicationsQuery,
  useStatsQuery,
  useThreadsQuery,
  useRefSourcesQuery,
} from "../../features";
import { PageTitle, Panel, Field, Select, ApiInfo, NoData } from "../../ui";
import s from "./publications.module.css";
import { trustMap } from "../../data";
import { useLocation } from "react-router";
import {useSmiTopQuery, useSocTopQuery} from "../../features";
import {useSearchParams} from "react-router-dom";

type ApiParams = {
  thread_id?: string;
  from?: string;
  to?: string;
  start?: number;
  limit?: number;
  filter?: {
    network_id?: number | number[];
    trustoption?: TrustValue;
    referenceFilter?: number[];
  };
};

export function Publications() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, expandFilters] = useState(false);
  const [typeId, setTypeId] = useState<string>(
    process.env.REACT_APP_SUBJECTS_ID as string
  );
  const [referenceId, setReferenceId] = useState<string>("");
  const [sourse, setSourse] = useState<string>("");
  const [sourceTitle, setSourceTitle] = useState<string>();
  const [profileID, setProfileID] = useState<string>(searchParams.get("profile_id") || '');
  const [networkID, setNetworkID] = useState<string>("");
  const [sourceType, setSourceType] = useState<number>();
  const statsQuery = useStatsQuery("");
  const findType = (item: any) => item.id === +typeId;
  const activeTypeItems = statsQuery.data?.find(findType)?.items ?? [];
  const [userFilter, setUserFilter] = useState([])
  const [type, setType] = useState("subject");
  const [url, setUrl] = useState(searchParams.get("it_filter") || '');

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID, REACT_APP_THREAD_ID } = process.env;

  const thread_id = REACT_APP_THREAD_ID;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;



  // Params are stored inside state, for easier comparison
  // inside useEffect (used inside useInfiniteQuery)
  const [apiParams, setApiParams] = useState<ApiParams>({});

  useEffect(() => {
    setApiParams({ ...apiParams, thread_id });
  }, [thread_id]);


  useEffect(() => {
    if (activeTypeItems && activeTypeItems.length > 0) {
      if (typeId === process.env.REACT_APP_SUBJECTS_ID) {
        setReferenceId(activeTypeItems[0].id)
      } else {
        setReferenceId("")
      }
    } else {
      setReferenceId("")
    }
  }, [typeId, activeTypeItems]);

  const activeTypeIds = activeTypeItems?.map((item:any)=> item.id)

  const sourcesQuery = useRefSourcesQuery({
    thread_id: thread_id,
    referenceFilter: referenceId ? [+referenceId] : activeTypeIds,
     network_id: [1, 2, 3, 4, 5, 7, 8],
    start: 0,
    limit: 10000
  });

  const sourcesList = sourcesQuery.data?.items ?? [];

  useEffect(() => {
    if (referenceId) {
      setApiParams((params) => ({
        ...params,
        filter: {
          ...params.filter,
          referenceFilter: referenceId ? [+referenceId] : activeTypeIds,
          profile_id: profileID ? profileID : undefined,
        },
      }));
    }
  }, [referenceId]);

  useEffect(() => {
    if (profileID) {
      setApiParams((params) => ({
        ...params,
        filter: {
          ...params.filter,
          referenceFilter: referenceId ? [+referenceId] : activeTypeIds,
          profile_id: profileID ? profileID : undefined,
          network_id: networkID ? [+networkID] : undefined,
          source_type: sourceType &&  sourceType>0 ? sourceType : undefined,
        },
      }));
      setSourceTitle(sourceTitle)
    }
  }, [profileID]);

  useEffect(() => {
    if (url) {
      setApiParams((params) => ({
        ...params,
        filter: {
          ...params.filter,
          referenceFilter: referenceId ? [+referenceId] : activeTypeIds,
          profile_id: profileID ? profileID : undefined,
          network_id: networkID ? [+networkID] : undefined,
          source_type: sourceType &&  sourceType>0 ? sourceType : undefined,
        },
      }));
      setSourceTitle(sourceTitle)
    }
  }, [url]);

  const { items: posts, query } = useInfiniteQuery(
    usePublicationsQuery,
    apiParams,
    (res: any) => res.data?.posts,
    !apiParams.thread_id
  );
  const allQueries = mergeQueries(query);
  const { isFetching, isError, error } = allQueries;

  const changeSourse = (evt:any) => {
    if (evt !== '') {
      setProfileID(evt.split(',')[1])
      setNetworkID(evt.split(',')[2])
      setSourceTitle(evt.split(',')[3])
    }
    else{
      setSourceType(0)
      setProfileID('0')
      setNetworkID('')
      setSourceTitle('')
    }
  }

console.log('sourse', sourse)

useEffect(() => {
  const names = sourcesList.find((el: { url: any; }) => el.url==url);
  url && changeSourse(url)
  console.log('names', names)

  if (names)
  {
    //item.profile_id, item.network_id, item.title
    setSourse(names.url)
    setProfileID(names.profile_id)
    setNetworkID(names.network_id)
    setSourceTitle(names.title)
    setReferenceId(referenceId)
    //activeTypeIds
    console.log(names.title)
    setUrl('')
  }
}, [profileID, referenceId]);

  function onFiltersUpdate(data: FilterData) {
    console.log('onFiltersUpdate data', data)
    console.log('apiParams 1', apiParams)
    setApiParams((params) => ({
      ...params,
      to: data.to,
      from: data.from,
      filter: {
        ...params.filter,
        network_id: data.network_id,
        trustoption: data.trustoption,
      },
    }));
    console.log('apiParams 2', apiParams)
  }

  return (
    <>
      <PageTitle>Публикации</PageTitle>
      <div className={clsx(s.grid, { [s.expand]: expanded })}>
        <Panel className={s.searchPanel} padding>
          <Field
            placeholder="Поиск"
            icon="search"
            className={s.search}
          />
          <div className={s.fieldContainer}>
            <label className={s.label} htmlFor="type">
              Тип:
            </label>
            <Select
              id="type"
              className={s.select}
              value={typeId}
              onChange={(event) => setTypeId(event.target.value)}
            >
              <option value={process.env.REACT_APP_SUBJECTS_ID}>Субъект</option>
              <option value={process.env.REACT_APP_EVENTS_ID}>Событие</option>
            </Select>
          </div>
          <div className={s.fieldContainer}>
            <label className={s.label} htmlFor="source">
              Субъект:
            </label>
            <Select
              id="source"
              className={s.select}
              disabled={activeTypeItems?.length === 0}
              value={referenceId}
              onChange={(event) => setReferenceId(event.target.value)}
            >
              <option value="">
                {statsQuery.isLoading ? "Загрузка" : "Выберите субъект"}
              </option>
              {activeTypeItems.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.keyword}
                </option>
              ))}
            </Select>
          </div>
          <div className={s.fieldContainer}>
            <label className={s.label} htmlFor="source">
              Источник:
            </label>
            <Select
              id="smi"
              className={s.select}
              disabled={sourcesList.length === 0}
              value={sourse}
              onChange={(event) => changeSourse(event.target.value)}
            >
              <option value="">
                {sourcesQuery.isLoading ? "Загрузка" : sourceTitle ? sourceTitle : "Выберите источник"}
              </option>
              {sourcesList.map((item: any) => (
                <option key={item.url} value={[item.url, item.profile_id, item.network_id, item.title]}>
                  {item.title}
                </option>
              ))}
            </Select>
          </div>
        </Panel>

        <div className={s.filterArea}>
          <PublicationsFilters
            expanded={expanded}
            onExpandToggle={expandFilters}
            onFilterChange={onFiltersUpdate}
          />
        </div>

        <div className={s.publications}>
          {!isFetching && !isError && posts.length === 0 && <NoData />}
          {posts.map((post: any) => (
            <Publication
              key={post.id}
              post={{
                text: post.text,
                title: post.title,
                coat: post.smi_type === "federal",
                date: new Date(post.created_date),
                // @ts-ignore
                type: trustMap[post.trust.trust],
                author: {
                  name: post.author,
                  avatar: post.author_logo,
                  url: post.author_url,
                },
                statistics: {
                  people: post.attendance,
                  views: post.viewed,
                  likes: post.likes,
                  comments: post.comments,
                  reposts: post.reposts,
                },
                networkName: post.network_name
              }}
              actions={{
                onCreateClick: () => console.log("onCreateClick"),
                onExcludeClick: () => console.log("onExcludeClick"),
                onReadClick: () => console.log("onReadClick"),
                onToneClick: () => console.log("onToneClick"),
              }}
            />
          ))}
          <ApiInfo isLoading={isFetching} isError={isError} error={error} />
        </div>
      </div>
    </>
  );
}
