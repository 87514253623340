import {
  toLocaleDate,
  toLocaleDateTime,
  useNewsbreakItemQuery,
  useGetAllPostsQuery,
  useGetAllParamsQuery
} from "../../features";
import { InfoDescription, InfoPanel, ApiInfo } from "../../ui";
import s from "./newsbreakItem.module.css";
import { Statistics } from "../../ui/statistics"
import posts from "../../ui/statistics/img/icon-posts.svg"

type Props = {
  title: string;
  id: number;
  number: number;
  thread_id: string;
};

export function NewsBreakItem({ title, id, number, thread_id }: Props) {
  const queryData = useNewsbreakItemQuery(id);
  const allPosts = useGetAllPostsQuery({})

  const { data, isSuccess, isLoading, isError, error } = queryData;

  let retroDate = allPosts?.data?.filter((item:any)=>item.id==data?.source_id[0])[0]?.retro
  function getNumberOfDays(start:any) { 
    const date1 = new Date(start); 
    const date2 = new Date(); 
    
    // One day in milliseconds 
    const oneDay = 1000 * 60 * 60 * 24; 
    
    // Calculating the time difference between two dates 
    const diffInTime = date2.getTime() - date1.getTime(); 
    
    // Calculating the no. of days between two dates 
    const diffInDays = Math.round(diffInTime / oneDay); 
    
    return diffInDays; 
    }
    
    const allParams = useGetAllParamsQuery({"thread_id":data?.source_id[0],"days":getNumberOfDays(retroDate)})
    console.log("allParams", allParams)

  if (!isSuccess) {
    return (
      <InfoPanel title="" number={number}>
        <ApiInfo isLoading={isLoading} isError={isError} error={error} />
      </InfoPanel>
    );
  }

  return (
    <InfoPanel title={title} number={number} statistics={{ publications: 0 }}>
      <div className={s.status}>
      {allParams.data?.total &&
      
        <div className={s.item}>
          
          <div className={s.counter}><img className={s.itemImage} src={posts} alt="" />{allParams.data?.total ?? "–"}</div>
        </div>
     
      }
      {allParams.data?.poststats &&
        <Statistics data={allParams.data?.poststats} />
      }
      </div>
      <InfoDescription
        className={s.description}
        title="Описание:"
        description={data.description}
      />
      <InfoDescription
        className={s.description}
        title="Ссылка:"
        description={data.url}
      />
      <InfoDescription
        className={s.description}
        title="Подпрограмма:"
        description={data.subprogram_id?.map((it: any, idx: number) => {
          return <span key={it.id}>{it.title}</span>
        })}
      />
      <div className={s.columns}>
        <InfoDescription
          title="Цель:"
          description={data.target || "–"}
        />
        <InfoDescription
          title="Региональный проект:"
          description={data.regional_project_id?.map((it: any, idx: number) => {
            return <span key={it.id}>{it.title}</span>
          })}
        />
        <InfoDescription title="Направления стратегии:" description={data.strategy_id?.title} />
        <InfoDescription title="Рекомендация к уровню планирования:" description={data.plan_lvl_id?.title} />
        <InfoDescription title="Сфера:" description={data.sphere_id?.map((it: any, idx: number) => {
          if (idx < data.sphere_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        })}/>
        <InfoDescription title="Целевая аудитория:" description={data.ca_id?.map((it: any, idx: number) => {
          if (idx < data.ca_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        })}/>
        <InfoDescription title="Каналы:" description={data.channel_id?.length > 0 ? data.channel_id?.map((it: any, idx: number) => {
          if (idx < data.channel_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "–"}/>
        <InfoDescription
          title="Локация:"
          description={data.location_id?.title}
        />
        <InfoDescription
          title="Медиакампания:"
          description={data.company_id?.title}
        />
        <InfoDescription
          title="Вид планируемого материала:"
          description={data.content_type_id?.title}
        />
        <InfoDescription
          title="Важность:"
          description={data.significance_id?.title}
        />
        <InfoDescription
          title="Бюджет/Ресурсы:"
          description={data.budget}
        />
        <InfoDescription title="Риск:" description={data.risk_id?.title} />
        <InfoDescription
          title="Дата ключевого события:"
          description={toLocaleDate(data.date_event)}
        />
        <InfoDescription
          title="Дата/время начала:"
          description={toLocaleDateTime(data.date_start)}
        />
        <InfoDescription
          title="Дата/время окончания:"
          description={toLocaleDateTime(data.date_finish)}
        />
        <InfoDescription title="Ответственный:" description={data.responsible_id?.title} />
        <InfoDescription title="Соисполнители:" description={data.coop_id?.length > 0 ? data.coop_id?.map((it: any, idx: number) => {
          if (idx < data.coop_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "–"}/>
        <InfoDescription title="Участники:" description={data.member_id?.length > 0 ? data.member_id?.map((it: any, idx: number) => {
          if (idx < data.member_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "–"}/>
        <InfoDescription title="Дополнительные участники:" description={data.add_member_id?.length > 0 ? data.add_member_id?.map((it: any, idx: number) => {
          if (idx < data.add_member_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "–"}/>
        <InfoDescription title="Автор:" description={data.author} />
        <InfoDescription title="Готовый материал:" 
        description={data?.source_id?.length > 0 ? (data?.source_id?.map((it:any)=><a key={it} href={'/materials/'+ it}>{allPosts?.data?.filter((item:any)=>item.id==it)[0]?.threadname ? allPosts?.data?.filter((item:any)=>item.id==it)[0]?.threadname :   it +' '}</a>)) : "-"}/>
        <InfoDescription title="ИОГВ:" 
        description={(data?.iogv_id?.title)}/>
      </div>
    </InfoPanel>
  );
}
