import clsx from "clsx";
import moment from 'moment'
import { networks as filterNetworks, TrustValue } from "../../../data";
import { Checkbox, DateTuple, TextButton } from "../../../ui";
import { useEffect, useState } from "react";
import { Button, DateRangePicker, Panel, Select } from "../../../ui";
import s from "./publications-filters.module.css";

export type FilterData = {
  network_id?: number[];
  from?: string;
  to?: string;
  trustoption?: TrustValue;
};

type Props = {
  expanded: boolean;
  onExpandToggle: (expanded: boolean) => void;
  onFilterChange: (data: FilterData) => void;
  className?: string;
};

export const PublicationsFilters = (props: Props) => {
  const { className, onFilterChange, expanded, onExpandToggle } = props;
  const [smi, setSmi] = useState(true);
  const [networks, setNetworks] = useState<number[]>([1,2,3,5,7,8]);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [trust, setTrust] = useState<TrustValue>("");

  function applyChanges() {
    const apiIds = [...networks];
    if (smi) apiIds.push(filterNetworks.smi.id);
    const startDateMonth = startDate ? startDate.getMonth() + 1 : 0;
    const endDateMonth = endDate ? endDate.getMonth() + 1 : 0;
    const startDateDay = startDate ? startDate.getDate() : 0;
    const endDateDay = endDate ? endDate.getDate() : 0;
    onFilterChange({
      from: moment(startDate)?.format('YYYY-MM-DD') + ' 00:00:00',
      //from: startDate?.toJSON(), T21:00:00.000Z
      to: moment(endDate)?.format('YYYY-MM-DD') + ' 23:59:59',
      trustoption: trust === "" ? undefined : trust,
      network_id: apiIds.length > 0 ? apiIds : undefined,
    });
  }

  function reset() {
    setStartDate(new Date());
    setEndDate(new Date());
    setTrust("");
    setSmi(true);
    setNetworks([1,2,3,5,7,8]);
    onFilterChange({});
  }

  function toggleSocials(checked: boolean) {
    const ids = filterNetworks.socials.map(({ id }) => id);
    setNetworks(checked ? ids : []);
  }

  function toggleSocial(id: number, checked: boolean) {
    const filtered = networks.filter((stateId) => stateId !== id);
    setNetworks(checked ? [...filtered, id] : filtered);
  }

  function selectAll() {
    toggleSocials(true);
    setSmi(true);
  }

  return (
    <Panel className={clsx(s.filters, className)} padding>
      <header className={s.header}>
        <label htmlFor="toggleFilter" className={s.expandLabel}>
          Фильтры
        </label>
        <Checkbox
          id="toggleFilter"
          className={s.expandInput}
          checked={expanded}
          onChange={() => onExpandToggle(!expanded)}
        />
      </header>
      {expanded && (
        <form
          className={s.content}
          onSubmit={(event) => {
            event.preventDefault();
            applyChanges();
          }}
        >
          <span className={s.subTitle}>Период</span>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={([startDate, endDate]: DateTuple) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
          <div className={s.sourceLine}>
            <span className={s.subTitle}>Источники</span>
            <TextButton onClick={() => selectAll()}>Выбрать все</TextButton>
          </div>

          <label className={s.check}>
            <Checkbox
              className={s.checkBox}
              checked={smi}
              onChange={() => setSmi((prevState) => !prevState)}
            />
            <span className={s.checkText}>СМИ</span>
          </label>
          <label className={s.check}>
            <Checkbox
              className={s.checkBox}
              checked={filterNetworks.socials.every(({ id }) =>
                networks.includes(id)
              )}
              onChange={({ target }) => toggleSocials(target.checked)}
            />
            <span className={s.checkText}>Социальные сети</span>
          </label>
          <div className={s.checkGroup}>
            {filterNetworks.socials.map(({ id, name }) => (
              <label key={id} className={s.check}>
                <Checkbox
                  className={s.checkBox}
                  checked={networks.includes(id)}
                  onChange={({ target }) => toggleSocial(id, target.checked)}
                />
                <span className={s.checkText}>{name}</span>
              </label>
            ))}
          </div>

          <span className={s.subTitle}>Тональность публикаций</span>
          <Select
            value={trust}
            onChange={(event) => setTrust(event.target.value as TrustValue)}
            type="solid"
          >
            <option value="">Любая</option>
            <option value="set">С лояльностью</option>
            <option value="unset">Без лояльности</option>
            <option value="1">Позитивная</option>
            <option value="-1">Негативная</option>
            <option value="0">Нейтральная</option>
          </Select>
          <div className={s.buttons}>
            <Button active={true}>Применить</Button>
            <TextButton className={s.reset} onClick={reset}>
              Сбросить
            </TextButton>
          </div>
        </form>
      )}
    </Panel>
  );
};
