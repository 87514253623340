import clsx from "clsx";
import { Panel } from "../panel";
import { Statistics } from "../statistics";
import news from "./img/icon-source.svg";
import iconTelegram from "./img/icon-telegram.svg";
import iconFacebook from "./img/icon-facebook.svg";
import iconInstagram from "./img/icon-instagram.svg";
import iconVk from "./img/icon-vk.svg";
import iconTwitter from "./img/icon-twitter.svg";
import iconYouTube from "./img/icon-youtube.svg";
import fallback from "./img/fallback.svg";
import s from "./post.module.css";
import {useState} from "react";

export type Author = {
  name: string;
  avatar: string;
  url: string;
};

export type Statistic = {
  people: number;
  views: number;
  likes: number;
  comments: number;
  reposts: number;
};

export type PostProps = {
  title: string;
  author: Author;
  text: string;
  coat: boolean;
  date: Date;
  statistics: Statistic;
  className?: string;
  type?: string;
  networkName?: string
};

const dateOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: "2-digit",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export function Post(props: PostProps) {
  const [showFullText, setShowFullText] = useState(false)
  const { className, title, author, text, type, statistics, coat, date, networkName } =
    props;

  const regex = /(<([^>]+)>)/gi;
  const safeText = text.replace(regex, "");
  const slicedText =
    safeText.length > 300 ? safeText.slice(0, 300) + "…" : safeText;
  let sourceIcon = news
  //console.log("props", props)
  switch (networkName) {
    case 'tg':
      sourceIcon = iconTelegram;
      break;
    case 'fb':
      sourceIcon = iconFacebook;
      break;
    case 'ig':
      sourceIcon = iconInstagram;
      break;
    case 'tw':
      sourceIcon = iconTwitter;
      break;
    case 'vk':
      sourceIcon = iconVk;
      break;
    case 'yt':
      sourceIcon = iconYouTube;
      break;
    default: sourceIcon = news
  }

  return (
    <Panel className={clsx(className, s.panel)} coat={coat}>
      <div
        className={clsx(s.post, {
          [s.default]: type === "default",
          [s.negative]: type === "negative",
          [s.positive]: type === "positive",
        })}
      >
        <div className={s.title}>
          <a href={author.url} className={s.origin}>
            <div className={s.icons}>
              <img
                className={s.avatar}
                src={author.avatar}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = fallback;
                }}
              />
              <img className={s.avatarIcon} src={sourceIcon} alt="" />
            </div>
            <div className={s.info}>
              <p className={s.site}>{author.name}</p>
              <p className={s.date}>
                {date.toLocaleDateString("ru", dateOptions)}
                &nbsp; | &nbsp;
                {date.toLocaleTimeString("ru", timeOptions)}
              </p>
            </div>
          </a>
          <Statistics className={s.statistics} data={statistics} networkName={networkName} />
        </div>
        <p className={s.headline}>{title ?? "–"}</p>
        <p className={s.text}>{safeText.length > 300 && !showFullText ? slicedText : safeText}  {safeText.length > 300 && !showFullText ? <span  className={s.showMore} onClick={() => setShowFullText(true)}>читать далее</span> : null}</p>
      </div>
    </Panel>
  );
}
