import clsx from "clsx";
import { PostRaiting } from "..";
import { TopicPopup } from "../../features";
import s from "./trending.module.css";

type Props = {
  data: any;
  number: number;
  className?: string;
  referenceId?: string;
};

export const Trend = ({ number, className, data, referenceId }: Props) => {
  console.log('data', data)
  const postRaitingData = {
    id: data.id,
    group_id: data.group_id,
    topic: data.title,
    subject: data.reference_item,
    level: 0,
    subjectPublications: data.postcount,
    subjectCoverage: data.attendance,
    totalPublications: data.owners_count,
    totalCoverage: data.total_attendance,
    dynamicData: data.graph,
    mediaPublications: data.smi_total_posts,
    socialMediaPublications: data.social_total_posts,
  };

  const content = (
    <div className={className}>
      <PostRaiting
        number={number}
        coat={data.smi_type === "federal"}
        className={s.topic}
        data={postRaitingData}
      />
    </div>
  );

  return <TopicPopup content={content} data={postRaitingData} referenceId={referenceId} />;
};
