import clsx from "clsx";
import s from "./table.module.css";
import { Link } from "react-router-dom";

type Props = {
  className?: string;
  data: TableData[];
};

type TableData = {
  title: string;
  subtitle: string;
  figures: any[];
};

export function Table({ className, data }: Props) {
  return (
    <div className={clsx(className, s.table)}>
      <div className={s.list}>
        {data.map((elem, index) => (
          <p className={s.item} key={index}>
            {elem.title}
          </p>
        ))}
      </div>
      {data.map((elem, index) => (
        <div className={s.data} key={index}>
      {elem.figures[5] ?
          <Link
           className={s.data__header}
            to={{
              pathname: "/publications",
              search: `?it_filter=${elem.figures[5]}`,
            }}
          >
            {elem.subtitle}
          </Link> :
          <p className={s.data__header}>{elem.subtitle}</p>
        }
          {elem.figures.slice(0, 5).map((figure, index) => (
            <p className={s.data__nums} key={index}>
              {figure}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
}
