import clsx from "clsx";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {useThreadItemQuery, useThreadsQuery} from "../../features";
import { ApiInfo, PageTitle, Panel, Field, Button, TextLoader } from "../../ui";
import { Material } from "./material";
import s from "./materials.module.css";

export function Materials() {
  const queryData = useThreadsQuery("");
  const { data, isSuccess, isLoading, isError, error } = queryData;

  return (
    <>
      <PageTitle>Готовые материалы</PageTitle>
      <Panel className={s.search} padding>
        <Field className={s.field} icon="search" />
        <Button active>ИОГВ</Button>
        <span className={s.count}>
          Всего готовых материалов: -
          {/* {isLoading ? <TextLoader /> : data?.count ?? "–"} */}
        </span>
      </Panel>

      <ApiInfo isLoading={isLoading} isError={isError} error={error} />

      {isSuccess && (
        <div className={s.list}>
          {data?.map((item: any, index: number) => (
            <Material number={index } {...item} key={item.id} />
          ))}
        </div>
      )}
    </>
  );
}
