import type { ReactNode } from "react";
import clsx from "clsx";
import coatImg from "./coat-of-arms.png";
import s from "./panel.module.css";

type Props = {
  children: ReactNode;
  className?: string;
  padding?: boolean;
  coat?: boolean;
  number?: number;
};

export function Panel(props: Props) {
  const { className, children, coat = false, padding = false, number } = props;
  const hasNumber = Number.isFinite(number);

  return (
    <div
      style={coat ? { backgroundImage: `url(${coatImg})` } : {}}
      className={clsx(className, s.panel, {
        [s.padding]: padding,
        [s.coat]: coat,
      })}
    >
      {!hasNumber && children}
      {hasNumber && (
        <div className={s.numberContainer}>
          <div className={s.number}>{number}</div>
          <div className={s.content}>{children}</div>
        </div>
      )}
    </div>
  );
}
