import clsx from "clsx";
import { Link, NavLink } from "react-router-dom";
import s from "./navigation.module.css";

type Props = {
  title: string;
  items: Item[];
  logo: string;
  className?: string;
};

type Item = {
  text: string;
  icon: string;
  href?: string;
  external?: boolean;
  hidden?: boolean;
  action?: () => void;
};

export function Navigation({ className, title, items, logo }: Props) {
  const itemContent = (item: Item) => {
    return (
      <>
        <span className={s.icons}>
          <img width="28" height="29" src={item.icon} alt={item.text} />
        </span>
        <span className={s.link}>{item.text}</span>
      </>
    );
  };
  const renderLink = (item: Item) => {
    if (item.external)
      return (
        <a className={s.item} href={item.href} target="_blank">
          {itemContent(item)}
        </a>
      );

    if (item.hidden) {
      return <div className={clsx(s.item, s.itemHidden)}>
        {itemContent(item)}
      </div>
    }

    return (
      <NavLink
        // @ts-ignore
        to={item.href}
        className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
      >
        {itemContent(item)}
      </NavLink>
    );
  };
  const renderButton = (item: Item) => {
    return (
      <button type="button" onClick={item.action} className={s.item}>
        {itemContent(item)}
      </button>
    );
  };

  return (
    <nav className={clsx(className, s.root)}>
      <Link className={s.logo} to="/">
        <img
          width="28"
          height="29"
          className={s.img}
          src={logo}
          alt="логотип"
        />
        <h1 className={s.title}>{title}</h1>
      </Link>

      <ul className={s.list}>
        {items.map((item, key) => (
          <li key={key}>
            {item.href && renderLink(item)}
            {item.action && renderButton(item)}
          </li>
        ))}
      </ul>
    </nav>
  );
}
