import type { ReactNode } from "react";
import NpmPopup from "reactjs-popup";
import { Panel } from "../panel";
import "reactjs-popup/dist/index.css";
import s from "./popup.module.css";
import "./popup.css";
import clsx from "clsx";

export type PopupContent = JSX.Element | ((isOpen: boolean) => JSX.Element);

type Props = {
  content: PopupContent;
  children: ReactNode;
  className?: string;
};

export const Popup = ({ children, content, className }: Props) => {
  return (
    <NpmPopup trigger={content} modal lockScroll>
      {(close: any) => (
        <Panel className={clsx(s.panel, className)} padding>
          <button className={s.close} type="button" onClick={close}>
            ×
          </button>
          {children}
        </Panel>
      )}
    </NpmPopup>
  );
};
