import {toLocaleDateTime, useRiskItemQuery, useGetAllPostsQuery} from "../../features";
import { ApiInfo, InfoDescription, InfoPanel, Panel } from "../../ui";
import s from "./risk.module.css";

type Props = {
  title: string;
  id: number;
  number: number;
};

export function Risk({ title, id, number }: Props) {
  const queryData = useRiskItemQuery(id);
  const allPosts = useGetAllPostsQuery({})
  const { data, isSuccess, isLoading, isError, error } = queryData;

  if (!isSuccess) {
    return (
      <InfoPanel title="" number={number}>
        <ApiInfo isLoading={isLoading} isError={isError} error={error} />
      </InfoPanel>
    );
  }

  return (
    <InfoPanel
      title={title}
      number={number}
      statistics={{ publications: 0, status: "–" }}
    >
      {data?.status &&
      <InfoDescription
        className={s.status}
        title="Статус"
        description={data?.status}
      />
      }
      <InfoDescription
        className={s.description}
        title="Отработка Риска:"
        description={data?.workout}
      />
      <InfoDescription
        className={s.description}
        title="Ссылка:"
        description={data.url}
      />
      <div className={s.columns}>
        <InfoDescription
          title="Целевая аудитория:"
          description={data.ca_id?.map((it: any, idx: number) => {
            if (idx < data.ca_id.length - 1) {
              return it.title + ', '
            }
            return it.title
          })}
        />
        <InfoDescription
          title="Бюджет/Ресурсы:"
          description={data.budget || '–'}
        />
        <InfoDescription
          title="Отвественный:"
          description={data?.responsible_id?.title || "–"}
        />
        <InfoDescription title="Соисполнители:" description={data.coop_id?.length > 0 ? data.coop_id?.map((it: any, idx: number) => {
          if (idx < data.coop_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "–"}/>
        <InfoDescription title="Участники:" description={data.member_id?.length > 0 ? data.member_id?.map((it: any, idx: number) => {
          if (idx < data.member_id.length - 1) {
            return it.title + ', '
          }
          return it.title
        }) : "–"}/>
        <InfoDescription
          title="Локация:"
          description={data?.location_id?.title}
        />
        <InfoDescription
          title="Дата/время начала:"
          description={toLocaleDateTime(data.date_start)}
        />
        <InfoDescription
          title="Дата/время окончания:"
          description={toLocaleDateTime(data.date_finish)}
        />
        <InfoDescription 
        title="ИОГВ:" 
        description={(data?.iogv_id?.title)}
        />
        <InfoDescription title="Готовый материал:" 
        description={data?.source_id?.length > 0 ? (data?.source_id?.map((it:any)=><a key={it} href={'/materials/'+ it}>{allPosts?.data?.filter((item:any)=>item.id==it)[0]?.threadname ? allPosts?.data?.filter((item:any)=>item.id==it)[0]?.threadname :   it +' '}</a>)) : "-"}/>
      </div>
    </InfoPanel>
  );
}
