import type { InputHTMLAttributes } from "react";
import clsx from "clsx";
import s from "./field.module.css";

type Props = {
  className?: string;
  value?: string;
  icon?: "search" | void;
  type?: string;
  [attr: string]: any;
} & InputHTMLAttributes<HTMLInputElement>;

export function Field({
  className,
  value,
  type = "text",
  icon,
  ...rest
}: Props) {
  return (
    <div className={clsx(s.container, className)}>
      <input
        type={type}
        className={clsx(s.input, { [s.hasIcon]: icon })}
        value={value}
        {...rest}
      />
      {icon && <div className={clsx(s.icon, s[`icon-${icon}`])} />}
    </div>
  );
}
