import type { ReactNode } from "react";
import type { StatisticType } from "../statistics";
import clsx from "clsx";
import { Panel } from "../panel";
import { Statistics } from "../statistics";
import s from "./info-panel.module.css";

type Props = {
  title: string;
  children: ReactNode;
  statistics?: StatisticType;
  number?: number;
  className?: string;
  contentClassName?: string;
};

export function InfoPanel({
  className,
  contentClassName,
  number,
  title,
  statistics,
  children,
}: Props) {
  return (
    <Panel number={number} padding={true} className={clsx(s.root, className)}>
      <div className={s.headContainer}>
        <div className={s.headLeft}>
          <p className={s.title}>
            <span>{title}</span>
          </p>
        </div>

        {/*{statistics && (*/}
        {/*  <div className={clsx(s.headRight, "not-ready")}>*/}
        {/*    <Statistics data={statistics} />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>

      <div className={contentClassName}>{children}</div>
    </Panel>
  );
}
