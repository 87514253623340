// import clsx from "clsx";
import clsx from "clsx";
import {ChangeEvent, useEffect, useState} from "react";
import {
  useThreadsQuery,
  useTopicsQuery,
  useRefSourcesQuery,
  useSmiQuery,
  mergeQueries, useStatsQuery,
} from "../../features";
import { useAppSelector } from "../../redux";
import {
  Button,
  PageTitle,
  Panel,
  ApiInfo,
  NoData,
  Topic,
  Field,
  Select,
} from "../../ui";
import s from "./topics.module.css";

const TopicList = ({ items }: any) => {
  return (
    <div className={s.list}>
      {items.map((item: any, index: number) => (
        <Topic key={item.id} number={index + 1} data={item} />
      ))}
    </div>
  )
}

export const Topics = () => {
  type SateType = "subject" | "event" | "smi";
  type PeriodType = "day" | "week" | "month";
  const [type, setType] = useState<SateType>("subject");
  const [period, setPeriod] = useState<PeriodType>("day");
  const [currentSourceId, setCurrentSourceId] = useState<string>("");
  const [userFilter, setUserFilter] = useState<{network_id: number, profile_id: string} | null>(null)

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID, REACT_APP_THREAD_ID } = process.env;

  const thread_id = +REACT_APP_THREAD_ID!;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;

  const user_id = useAppSelector((state) => state.auth.userId);

  const sourcesQuery = useRefSourcesQuery({
    thread_id: thread_id,
    // network_id: ['4'],
    // search_string: '',
    referenceFilter: reference_id ? [+reference_id] : undefined,
    start: 0,
    limit: 100
  });
  const sourcesList = sourcesQuery.data?.items ?? [];

  // I have no idea why, but we have to pass all of this
  // to the api (even if it works without)
  const wtfHardCodedParams = {
    reference_item_id: 0,
    // type: "social",
    type: "smi",
    user_id,
    from: "",
    to: "",
    // sort: {
    //   type: "rating",
    //   order: "desc"
    // },
    user_filter: userFilter
  };

  // ! ADD INFINITE SCROLLING
  const topicsParams = {
    start: 0,
    limit: 2000,
    thread_id,
    reference_id,
    period,
    ...wtfHardCodedParams,
  };

  const skipParams = { skip: !thread_id || type === "smi" };
  const topicsQuery = useTopicsQuery(topicsParams, skipParams);

  const smiQuery = useSmiQuery("", { skip: type !== "smi" });
  const allQueries = mergeQueries(topicsQuery, smiQuery);

  console.info(`🔥 allQueries`, allQueries);
  const { isSuccess, isFetching, isError, error } = allQueries;
  const items = type === "smi" ? smiQuery.data : topicsQuery.data?.items;
  const [filteredData, setFilteredData] = useState([] as any[]);
  const [searchString, setSearchString] = useState('');
  const [onlyFederal, setOnlyFederal] = useState(false);

  useEffect(() => {
    if (currentSourceId) {
      const currentSource = sourcesList.data?.find((it: any) => it.inner_id === currentSourceId)
      if (currentSource) {
        setUserFilter({
          network_id: currentSource.network_id,
          profile_id: currentSource.inner_id
        })
      }
    } else {
      setUserFilter(null)
    }
  }, [currentSourceId])

  useEffect(() => {
    setFilteredData([])
    if (items) {
      let currentItems = []
      if (searchString === '') {
        //currentItems = items
        currentItems = [...items].sort((a:any, b:any) => a.storyDocs < b.storyDocs ? 1 : -1)
      } else {
        console.log(items)
        currentItems = [...items].filter((it: { reference_item: string, title: string }) => it.reference_item.toLowerCase().includes(searchString.toLowerCase()) || it.title.toLowerCase().includes(searchString.toLowerCase()))
      }
      setFilteredData(onlyFederal ? currentItems.filter((it: any) => it.smi_type === "federal") : currentItems)
    }
  }, [searchString, onlyFederal, items])

  console.log('filteredData', filteredData)

  const searchHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchString(evt.target.value)
  }

  return (
    <>
      <PageTitle>Темы дня: СМИ и соцсети</PageTitle>
      <Panel className={s.filter} padding>
        <div className={s.filterLine}>
          <Field
            placeholder="Поиск"
            icon="search"
            className={s.search}
            onChange={searchHandle}
            value={searchString}
          />
          <Button
            className={s.tab}
            active={type === "subject"}
            onClick={() => setType("subject")}
          >
            Субъект
          </Button>
          <Button
            className={s.tab}
            active={type === "event"}
            onClick={() => setType("event")}
          >
            Событие
          </Button>
          <Button
            className={s.tab}
            active={type === "smi"}
            onClick={() => setType("smi")}
          >
            Новости Спб
          </Button>
          <Button
            className={s.tab}
            active={onlyFederal}
            onClick={() => setOnlyFederal(prevState => !prevState)}
          >
            Федеральная повестка
          </Button>
        </div>
        <div className={s.filterLine}>
          <label className={s.range}>
            <span className={s.label}>Период</span>
            <Select
              disabled={type === "smi"}
              className={s.select}
              value={period}
              onChange={(event) => setPeriod(event.target.value as PeriodType)}
            >
              <option value="day">День</option>
              <option value="week">Неделя</option>
              <option value="month">Месяц</option>
            </Select>
          </label>
        </div>
      </Panel>

      <ApiInfo isLoading={isFetching} isError={isError} error={error} />
      {isSuccess && filteredData.length < 1 && <NoData />}
      {isSuccess && filteredData.length > 0 && (
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <Topic key={item.id} number={index + 1} data={item} referenceId={reference_id} />
          ))}
        </div>
      )}
    </>
  );
};
