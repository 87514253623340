import clsx from "clsx";
import {useStatsQuery, useRiskListQuery, useSmiTopQuery, useSocTopQuery, useSmiGraphQuery, useSocGraphQuery, useUnatedGraphQuery, useIndicatorsTopQuery, useGetAllPostsQuery} from "../../features";
import { Panel, StatsPanel, Table } from "../../ui";
import { Title } from "../../ui/panel/title";
import { Tooltip, Bar, XAxis, YAxis, Legend } from "recharts";
import { BarChart } from "../../ui";

import s from "./statistic.module.css";

type DataItem = {
  id: number;
  title: number;
  items: any[];
};

const getMonitoringCount = (data: DataItem[], id: string) => {
  const arr = data?.find((item: any) => item.id === +id);
  return arr?.items.length;
};

export function Statistic() {
  const result = useStatsQuery("");
  const subjectsCount = getMonitoringCount(
    result.data,
    process.env.REACT_APP_SUBJECTS_ID as string
  );
  const eventsCount = getMonitoringCount(
    result.data,
    process.env.REACT_APP_EVENTS_ID as string
  );
  const queryData = useRiskListQuery({ start: 0, limit: 0 });
  console.log("#queryData", queryData);

  const indicatorsTopQueryData = useIndicatorsTopQuery({});
  const smiTopQueryData = useSmiTopQuery({});
  const socTopQueryData = useSocTopQuery({});
  const smiGraphQueryData = useSmiGraphQuery({});
  const socGraphQueryData = useSocGraphQuery({});
  const unatedGraphQueryData = useUnatedGraphQuery({});
  const allPosts = useGetAllPostsQuery({})

  //console.log("#smiTopQueryData", smiTopQueryData);
  //console.log("#socTopQueryData", socTopQueryData);
  //console.log("#indicatorsTopQueryData", indicatorsTopQueryData);
  console.log("#allPosts", allPosts);

  const tableData = [
    {
      title: "Название",
      subtitle: "Количество публикаций",
      figures: ['', indicatorsTopQueryData.data?.posts.total || 0, indicatorsTopQueryData.data?.posts.positive || 0, indicatorsTopQueryData.data?.posts.netural || 0, indicatorsTopQueryData.data?.posts.negative || 0],
    },
    {
      title: "Всего",
      subtitle: "Количество комментариев",
      figures: ['', indicatorsTopQueryData.data?.comments.total || 0, indicatorsTopQueryData.data?.comments.positive || 0, indicatorsTopQueryData.data?.comments.netural || 0, indicatorsTopQueryData.data?.comments.negative || 0],
    },
    {
      title: "Поз.",
      subtitle: "Количество репостов",
      figures: ['', indicatorsTopQueryData.data?.reposts.total || 0, indicatorsTopQueryData.data?.reposts.positive || 0, indicatorsTopQueryData.data?.reposts.netural || 0, indicatorsTopQueryData.data?.reposts.negative || 0],
    },
    {
      title: "Нейт.",
      subtitle: "Количество лайков",
      figures: ['', indicatorsTopQueryData.data?.likes.total || 0, indicatorsTopQueryData.data?.likes.positive || 0, indicatorsTopQueryData.data?.likes.netural || 0, indicatorsTopQueryData.data?.likes.negative || 0],
    },
    {
      title: "Негат.",
      subtitle: "Количество просмотров",
      figures: ['', indicatorsTopQueryData.data?.views.total || 0, indicatorsTopQueryData.data?.views.positive || 0, indicatorsTopQueryData.data?.views.netural || 0, indicatorsTopQueryData.data?.views.negative || 0],
    },
  ];

  if (!smiTopQueryData.data || !socTopQueryData.data) {
    return null
  }

  const tableSmiData = [
    {
      title: "Название",
      subtitle: smiTopQueryData.data[0].title || '',
      figures: [<a target="_blank" href={smiTopQueryData.data[0].url}>{smiTopQueryData.data[0].url}</a> || '', smiTopQueryData.data[0].total || 0, smiTopQueryData.data[0].positive || 0, smiTopQueryData.data[0].netural || 0, smiTopQueryData.data[0].negative || 0, smiTopQueryData.data[0].url || '', smiTopQueryData.data[0].profile_id || ''],
    },
    {
      title: "Всего",
      subtitle: smiTopQueryData.data[1].title || '',
      figures: [<a target="_blank" href={smiTopQueryData.data[1].url}>{smiTopQueryData.data[1].url}</a> || '', smiTopQueryData.data[1].total || 0, smiTopQueryData.data[1].positive || 0, smiTopQueryData.data[1].netural || 0, smiTopQueryData.data[1].negative || 0, smiTopQueryData.data[1].url || '', smiTopQueryData.data[1].profile_id || ''],
    },
    {
      title: "Поз.",
      subtitle: smiTopQueryData.data[2].title || '',
      figures: [<a target="_blank" href={smiTopQueryData.data[2].url}>{smiTopQueryData.data[2].url}</a> || '', smiTopQueryData.data[2].total || 0, smiTopQueryData.data[2].positive || 0, smiTopQueryData.data[2].netural || 0, smiTopQueryData.data[2].negative || 0, smiTopQueryData.data[2].url || '', smiTopQueryData.data[2].profile_id || ''],
    },
    {
      title: "Нейт.",
      subtitle: smiTopQueryData.data[3].title || '',
      figures: [<a target="_blank" href={smiTopQueryData.data[3].url}>{smiTopQueryData.data[3].url}</a> || '', smiTopQueryData.data[3].total || 0, smiTopQueryData.data[3].positive || 0, smiTopQueryData.data[3].netural || 0, smiTopQueryData.data[3].negative || 0, smiTopQueryData.data[3].url || '', smiTopQueryData.data[3].profile_id || ''],
    },
    {
      title: "Негат.",
      subtitle: smiTopQueryData.data[4].title || '',
      figures: [<a target="_blank" href={smiTopQueryData.data[4].url}>{smiTopQueryData.data[4].url}</a> || '', smiTopQueryData.data[4].total || 0, smiTopQueryData.data[4].positive || 0, smiTopQueryData.data[4].netural || 0, smiTopQueryData.data[4].negative || 0, smiTopQueryData.data[4].url || '', smiTopQueryData.data[4].profile_id || ''],
    },
  ];

  const tableSocData = [
    {
      title: "Название",
      subtitle: socTopQueryData.data[0].title || '',
      figures: [<a target="_blank" href={socTopQueryData.data[0].url}>{socTopQueryData.data[0].url}</a> || '', socTopQueryData.data[0].total || 0, socTopQueryData.data[0].positive || 0, socTopQueryData.data[0].netural || 0, socTopQueryData.data[0].negative || 0, socTopQueryData.data[0].url || '', socTopQueryData.data[0].profile_id || ''],
    },
    {
      title: "Всего",
      subtitle: socTopQueryData.data[1].title || '',
      figures: [<a target="_blank" href={socTopQueryData.data[1].url}>{socTopQueryData.data[1].url}</a> || '', socTopQueryData.data[1].total || 0, socTopQueryData.data[1].positive || 0, socTopQueryData.data[1].netural || 0, socTopQueryData.data[1].negative || 0, socTopQueryData.data[1].url || '', socTopQueryData.data[1].profile_id || ''],
    },
    {
      title: "Поз.",
      subtitle: socTopQueryData.data[2].title || '',
      figures: [<a target="_blank" href={socTopQueryData.data[2].url}>{socTopQueryData.data[2].url}</a> || '', socTopQueryData.data[2].total || 0, socTopQueryData.data[2].positive || 0, socTopQueryData.data[2].netural || 0, socTopQueryData.data[2].negative || 0, socTopQueryData.data[2].url || '', socTopQueryData.data[2].profile_id || ''],
    },
    {
      title: "Нейт.",
      subtitle: socTopQueryData.data[3].title || '',
      figures: [<a target="_blank" href={socTopQueryData.data[3].url}>{socTopQueryData.data[3].url}</a> || '', socTopQueryData.data[3].total || 0, socTopQueryData.data[3].positive || 0, socTopQueryData.data[3].netural || 0, socTopQueryData.data[3].negative || 0, socTopQueryData.data[3].url || '', socTopQueryData.data[3].profile_id || ''],
    },
    {
      title: "Негат.",
      subtitle: socTopQueryData.data[4].title || '',
      figures: [<a target="_blank" href={socTopQueryData.data[4].url}>{socTopQueryData.data[4].url}</a> || '', socTopQueryData.data[4].total || 0, socTopQueryData.data[4].positive || 0, socTopQueryData.data[4].netural || 0, socTopQueryData.data[4].negative || 0, socTopQueryData.data[4].url || '', socTopQueryData.data[4].profile_id || ''],
    },
  ];

  const barChartData = [
    {
      name: "Публикации",
      Комментарии: 125,
      Публикации: indicatorsTopQueryData.data?.posts.total || 0,
    },
    {
      name: "Лайки",
      Комментарии: 75,
      Публикации: indicatorsTopQueryData.data?.likes.total || 0,
    },
    {
      name: "Репосты",
      Комментарии: 75,
      Публикации: indicatorsTopQueryData.data?.reposts.total || 0,
    },
    {
      name: "Комментарии",
      Комментарии: 100,
      Публикации: indicatorsTopQueryData.data?.comments.total || 0,
    },
    {
      name: "Просмотры",
      Комментарии: 25,
      Публикации: (indicatorsTopQueryData.data?.views.total / 100) || 0,
    },
  ];

  console.log('smiGraphQueryData', smiGraphQueryData)
  let smiGraphData = [];
  if (smiGraphQueryData.data) {
    smiGraphData = smiGraphQueryData.data?.map((it: any) => ({
      name: it[0].split(' ')[1],
      Публикации: it[1] || 0,
    })) || []
  }

  console.log('socGraphQueryData', socGraphQueryData)
  let socGraphData = [];
  if (socGraphQueryData.data) {
    socGraphData = socGraphQueryData.data?.map((it: any) => ({
      name: it[0].split(' ')[1],
      Публикации: it[1] || 0,
    })) || []
  }

  console.log('unatedGraphQueryData', unatedGraphQueryData)
  let unatedGraphData = [];
  if (unatedGraphQueryData.data) {
    unatedGraphData = unatedGraphQueryData.data?.posts.map((it: any, idx: number) => {
      const comments = unatedGraphQueryData.data?.comments[idx][1] || 0;
      return {
        name: it[0].split(' ')[1],
        Комментарии: comments,
        Публикации: it[1] || 0,
      }
    }) || [];
  }

  const barChartDataSmi = [
    {
      name: smiTopQueryData.data[0]?.title || '',
      Публикации: smiTopQueryData.data[0]?.total || 0,
    },
    {
      name: smiTopQueryData.data[1]?.title || '',
      Публикации: smiTopQueryData.data[1]?.total || 0,
    },
    {
      name: smiTopQueryData.data[2]?.title || '',
      Публикации: smiTopQueryData.data[2]?.total || 0,
    },
    {
      name: smiTopQueryData.data[3]?.title || '',
      Публикации: smiTopQueryData.data[3]?.total || 0,
    },
    {
      name: smiTopQueryData.data[4]?.title || '',
      Публикации: smiTopQueryData.data[4]?.total || 0,
    }
  ];

  const barChartDataSoc = [
    {
      name: socTopQueryData.data[0]?.title || '',
      Публикации: socTopQueryData.data[0]?.total || 0,
    },
    {
      name: socTopQueryData.data[1]?.title || '',
      Публикации: socTopQueryData.data[1]?.total || 0,
    },
    {
      name: socTopQueryData.data[2]?.title || '',
      Публикации: socTopQueryData.data[2]?.total || 0,
    },
    {
      name: socTopQueryData.data[3]?.title || '',
      Публикации: socTopQueryData.data[3]?.total || 0,
    },
    {
      name: socTopQueryData.data[4]?.title || '',
      Публикации: socTopQueryData.data[4]?.total || 0,
    }
  ];

  return (
    <>
      <div className={s.statsGrid}>
        <div className={s.stats}>
          <StatsPanel
            className={s.stat}
            title="Всего субъектов мониторинга"
            count={subjectsCount}
          />
          <StatsPanel
            className={s.stat}
            title="Всего событий мониторинга"
            count={eventsCount}
          />
        </div>
        <div className={s.stats}>
          <StatsPanel
            className={s.stat}
            title="Всего готовых материалов"
            count={allPosts.data?.length-1}
          />
          <StatsPanel
            className={s.stat}
            title="Количество активных рисков"
            count={queryData.data?.items.length}
          />
        </div>
      </div>
      <div className={s.panels}>
        <Panel className={s.table}>
          <div className={s.header}>
            <Title className={s.title} caption="Показатели" />
            <span className={s.subtitle}>Данные за последние 24 часа</span>
          </div>
          <Table data={tableData} />
        </Panel>
        <Panel className={s.panel} padding>
          <BarChart data={unatedGraphData} height={299}>
            <Bar dataKey="Комментарии" fill="#8ACE21" />
            <Bar
              dataKey="Публикации"
              fill="#4EC0E4"
            />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" align="right" />
          </BarChart>
        </Panel>
        <Panel className={s.table}>
          <div className={s.header}>
            <Title className={s.title} caption="Топ источников СМИ" />
            <span className={s.subtitle}>Данные за последние 24 часа</span>
          </div>
          <Table data={tableSmiData} />
        </Panel>
        <Panel className={s.panel} padding>
          <span className={s.chartTitle}>Динамика публикаций по источникам СМИ</span>
          <BarChart data={smiGraphData} height={299}>
            {/* <Bar dataKey="Комментарии" fill="#8ACE21" /> */}
            <Bar
              dataKey="Публикации"
              fill="#4EC0E4
"
            />
            <XAxis dataKey="name" angle={-45} />
            <YAxis />
            <Tooltip />
            {/*<Legend verticalAlign="top" align="right" />*/}
          </BarChart>
        </Panel>
        <Panel className={s.table}>
          <div className={s.header}>
            <Title className={s.title} caption="Топ источников соц.сети" />
            <span className={s.subtitle}>Данные за последние 24 часа</span>
          </div>
          <Table data={tableSocData} />
        </Panel>
        <Panel className={s.panel} padding>
          <span className={s.chartTitle}>Динамика публикаций по источникам Соц сетей</span>
          <BarChart data={socGraphData} height={299}>
            {/* <Bar dataKey="Комментарии" fill="#8ACE21" /> */}
            <Bar
              dataKey="Публикации"
              fill="#4EC0E4
"
            />
            <XAxis dataKey="name" angle={-45} />
            <Tooltip />
            <YAxis />
            {/*<Legend verticalAlign="top" align="right" />*/}
          </BarChart>
        </Panel>
      </div>
    </>
  );
}
