import type { Data } from "../../features";
import clsx from "clsx";
import { Panel } from "../panel";
import { Man } from "../man";
import s from "./post-raiting.module.css";
import { PublicationsChart } from "../../features";
import { TopicDescription } from "../topic-description";

type Props = {
  number: number;
  data: PostRaitingData;
  className?: string;
  coat?: boolean;
};

export type PostRaitingData = {
  id: string;
  group_id: string;
  subject: string;
  level: number;
  topic: string;
  subjectPublications: number;
  totalPublications: number;
  subjectCoverage: number;
  totalCoverage: number;
  dynamicData: Data[];
  mediaPublications?: number;
  socialMediaPublications?: number;
};

export function PostRaiting({ className, number, coat = false, data }: Props) {
  const {
    group_id,
    subject,
    level,
    topic,
    subjectPublications,
    subjectCoverage,
    mediaPublications,
    totalPublications,
    totalCoverage,
    socialMediaPublications,
    dynamicData,
  } = data;
  return (
    <Panel padding coat={coat} number={number} className={className}>
      <div className={s.media}>
        <div className={s.theme}>
          <p className={s.theme__caption}>Тема:</p>
          <h2 className={s.theme__title}>{topic}</h2>
          <div className={s.data}>
            <div className={s.dataCol}>

              <TopicDescription
                title="Количество источников"
                description={totalPublications}
                coverage={totalCoverage}
              />
            </div>

          </div>
        </div>
        <div className={s.diagrams}>
          {/*<div className={clsx(s.diagram, { "not-ready": !dynamicData })}>*/}
          <div className={s.diagram}>
            <p>Динамика публикаций:</p>
            <Panel className={clsx(s.diagramPanel)}>
              {dynamicData && <PublicationsChart data={dynamicData} />}
            </Panel>
          </div>

          {/*<div className={clsx(s.diagram, "not-ready")}>*/}
          {/*  <p>Тональность публикаций:</p>*/}
          {/*  <Panel className={s.diagramPanel} padding>*/}
          {/*    GRAPHIC*/}
          {/*  </Panel>*/}
          {/*</div>*/}
        </div>
      </div>
    </Panel>
  );
}
